window.addEventListener('load', () => {
    const navbar = document.getElementById("navbar")
    const scrollBeacon = document.getElementById("scroll-beacon")
    const navObserver = new IntersectionObserver(
        ([e]) => navbar.classList.toggle("inverted", e.intersectionRatio < 1),
        {threshold: [1]}
    );
    navObserver.observe(scrollBeacon);

    const questionLabels = document.querySelectorAll('.question-label')
    const questions = document.querySelectorAll('.question')

    const renderQuestion = (label) => {
        questions.forEach((question) => {
            if (question.dataset.id === label.dataset.id) {
                question.style.display = 'inherit'
                question.classList.add('active')
                return
            }

            question.classList.remove('active')
            question.style.display = 'none'
        })

        questionLabels.forEach((cmp) => {
            if (label.dataset.id === cmp.dataset.id) {
                cmp.classList.add('active')
                return
            }

            cmp.classList.remove('active')
        })
    }

    questionLabels.forEach((label, index) => {
        label.addEventListener('mouseover', () => {
            if (label.classList.contains('active')) {
                return
            }

            renderQuestion(label)

            label.addEventListener('mouseout', () => {
                renderQuestion(questionLabels[index])
            }, {once: true})
        })
        // support for touch devices
        label.addEventListener('click', () => {
            if (label.classList.contains('active')) {
                return
            }

            renderQuestion(label)
        })
    })

    const callback = function (entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add("animate-fade");
            }
        });
    };
    const observer = new IntersectionObserver(callback);
    const targets = document.querySelectorAll(".js-show-on-scroll");
    targets.forEach(function (target) {
        target.classList.add("opacity-0");
        observer.observe(target);
    });

    document.querySelectorAll('.newsletter').forEach((el: HTMLFormElement) =>
    {
        console.log(el)
        el
            .addEventListener('submit', (e: SubmitEvent) => {
                const message: HTMLElement = el.querySelector('.newsletter-message')

                e.preventDefault()

                message.style.display = 'none'

                fetch('https://portal.pivotalcontest.org/newsletter/subscribe', {
                    method: 'POST',
                    body: new FormData(el),
                    headers: {
                        'Accept': 'application/json'
                    }
                }).then(res => res.json()).then(res => {
                    message.classList.remove('text-green-500', 'text-red-500')

                    if (res.success) {
                        message.classList.add('text-green-500')
                    } else {
                        message.classList.add('text-red-500')
                    }
                    message.innerText = res.message
                }).catch((err) => {
                    console.error(err);
                    message.innerText = "An error occurred. Please try again later."
                })

                message.style.display = 'block'
            });
    })

    const openMenu = document.getElementById('open-menu')
    const closeMenu = document.getElementById('close-menu')
    const toggleWithMenu = document.querySelectorAll('.toggle-with-menu');

    [openMenu, closeMenu].forEach(el => el.addEventListener(
        'click',
        () => toggleWithMenu.forEach(el => el.classList.toggle('hidden')))
    )
})